<div class="row">
  <div class="col-md-4"></div>
  <div class="col-md-4">
    <div class="mat-dialog-container module-changer p-4 mt-5">
      <p class="text-center mt-3 info-icon">
        <i class="fas fa-info-circle color-orange"></i>
      </p>

      <h1 class="text-center text-dark fs-4">
        Which course do you want to try?
      </h1>

      <select class="form-select w-75 mx-auto" [(ngModel)]="course">
        <option value="">Select course</option>

        <option [value]="_module.name" *ngFor="let _module of modules">
          {{ _module.title }}
        </option>
      </select>

      <div class="action mt-4 text-center">
        <button class="btn brand-btn-primary" (click)="changeModule()">
          Select
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-4"></div>
</div>
