import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _isValid = this.authService.isLoggedIn();
    if (!_isValid) {
      this.router.navigate(['/login'], {
        queryParams: { redirect_uri: window.location.href },
      });
    }
    return true;
  }
}
