import { InvalidateTokenComponent } from './invalidate-token/invalidate-token.component';
import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RedirectIfAuthenticated } from './redirect-if-authenticated.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from './signup/signup.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [RedirectIfAuthenticated],
    component: LoginComponent,
    data: { showHeader: false, showSidebar: false } 
  },
  {
    path: 'invalidate',
    canActivate: [AuthGuard],
    component: InvalidateTokenComponent,
    data: { showHeader: false, showSidebar: false } 
  },
  {
    path: 'auth/google/redirect',
    component: SocialCallbackComponent,
    data: { showHeader: false, showSidebar: false } 
  },
  {
    path: 'signup',
    canActivate: [RedirectIfAuthenticated],
    component: SignupComponent,
    data: { showHeader: false, showSidebar: false } 
  },
  {
    path: 'forgot-password',
    canActivate: [RedirectIfAuthenticated],
    component: ForgotPasswordComponent,
    data: { showHeader: false, showSidebar: false } 
  },
  {
    path: 'reset/:token',
    component: ResetPasswordComponent,
    data: { showHeader: false, showSidebar: false } 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
