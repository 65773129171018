import { PaymentService } from '../../pages/payment/payment.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Package } from '../../admin/packages/package';
import { Component, Input } from '@angular/core';
import { WebinarService } from '../webinar.service';
import { WebinarDetailPopupComponent } from '../webinar-detail-popup/webinar-detail-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-webinar-list',
  templateUrl: './webinar-list.component.html',
  styleUrls: ['./webinar-list.component.scss'],
})
export class WebinarListComponent {
  @Input() packages: any[] = [];
  @Input() grid: number = 2;
  constructor(
    public auth: AuthService,
    private webinarService: WebinarService,
    private orderService: PaymentService,
    private dialog: MatDialog
  ) {}

  textLimit(text: string, limit: number) {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }

  bookTest(_package: Package) {
    this.webinarService.createOrder(_package).subscribe(
      (res: any) => {
        window.open(res.uri);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openWebinarDetailPopup(webinar: any) {
    this.dialog.open(WebinarDetailPopupComponent, {
      minWidth: '45vw',
      maxWidth: '50vw',
      data: {
        webinar: { webinar },
      },
    });
  }
}
