import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { _modules } from '../../model/model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-module-changer',
  templateUrl: './module-changer.component.html',
  styleUrls: ['./module-changer.component.scss'],
})
export class ModuleChangerComponent {
  modules = _modules;
  course: string = '';
  constructor(
    public dialogRef: MatDialogRef<ModuleChangerComponent>,
    private auth: AuthService
  ) {}
  selectedModule(e: any) {}
  changeModule() {
    localStorage.setItem('module', this.course);
    this.auth.changeModule(this.course);
    this.auth.moduleChanged.subscribe({
      next: (res) => {
        setTimeout(() => {
          if (res) {
            this.dialogRef.close();
            window.location.reload();
          }
        }, 500);
      },
    });
  }
}
