import * as moment from 'moment';
import { environment } from 'src/environments/environment';

export class Model {
  public toLoacale(date: string) {
    return moment(date).format('DD-MM-YYYY');
  }

  public toLoacaleForChart(date: string, type: string) {
    if (type == 'daily') {
      return moment(date).format('LT');
    }

    if (type == 'weekly') {
      return moment(date).format('ddd');
    }

    if (type == 'monthly') {
      return moment(date).format('DD, MMM');
    }

    if (type == 'yearly') {
      return moment(date).format('MMM');
    }
    return;
  }

  public dateRange(type: string) {
    let date = moment.now();
    let range = '';
    if (type == 'daily') {
      moment(date);
    }

    if (type == 'weekly') {
      return moment(date).format('ddd');
    }

    if (type == 'monthly') {
      return moment(date).format('DD, MMM');
    }

    if (type == 'yearly') {
      return moment(date).format('MMM');
    }
    return;
  }

  public assetPath(type: string, fileName: string) {
    return `${environment.api}${environment.assets}${type}/${fileName}`;
  }
}

export const _modules = [
  {
    name: 'celpip',
    title: 'CELPIP',
    icon: 'assets/img/celpip.png',
  },
  {
    name: 'ielts',
    title: 'IELTS Academic',
    icon: 'assets/img/ielts.png',
  },
  //   {
  //     name: 'pte',
  //     title: 'PTE',
  //     icon: 'assets/img/pte.png',
  //   },
  // {
  //   name: 'toefl',
  //   title: 'TOEFL',
  //   icon: 'assets/img/toefl.png'
  // }
];
