<app-header *ngIf="showHeader"></app-header>
<app-test-runner-header
  *ngIf="!showHeader && testRunnerHeader"
></app-test-runner-header>

<div class="main">
  <router-outlet></router-outlet>
</div>
<app-footer *ngIf="showFooter"></app-footer>
<div class="py-2 mt-2" *ngIf="!showHeader && !showFooter && testRunnerHeader">
  <p class="text-center">
    © All Rights Reserved <a target="_blank" [href]="apiUrl">Allmock</a> 2023
  </p>
</div>
