import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, ...args: string[]): unknown {
    let [format] = args;
    return moment(value).format(format);
  }
}
