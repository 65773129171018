<div class="dialog-header">
  <h3 class="dialog-title">
    {{ webinar.title }}
    <i
      (click)="dialogRef.close()"
      class="far fa-times-circle float-end me-3 mt-1 fs-5 link"
    ></i>
  </h3>
</div>

<div class="card">
  <!-- <img [src]="news.image" class="card-img-top img-fluid" alt=""> -->
  <div class="card-body">
    <div class="container">
      <!-- <div class="row">
          <div class="col-lg-12">
            <p class="text-success">
              Success! You have successfully subscribed. Please check your registered email ID for details.
            </p>
          </div>
        </div> -->
      <div class="row">
        <div class="col-md-4 px-0">
          <img [src]="webinar?.image" class="img-fluid rounded-4" alt="" />
        </div>
        <div class="col-md-8">
          <p class="card-text" [innerHTML]="webinar.description"></p>

          <p *ngIf="webinar.startDate">
            Date & Time :
            <b>
              {{ webinar.startDate | date : "d MMM, YYYY" }}
              {{ webinar.time | date : "h:mm:ss a" }}
            </b>
          </p>
          <p class="m-0" *ngIf="webinar.webinarLink">
            Webinar Link : <b> {{ webinar.webinarLink }} </b>
          </p>
          <p class="m-0" *ngIf="webinar.passcode">
            Passcode : <b> {{ webinar.passcode }} </b>
          </p>
          <p class="m-0" *ngIf="webinar.webinarId">
            Webinar Id : <b> {{ webinar.webinarId }} </b>
          </p>

          <p class="mt-2" *ngIf="webinar?.instructor">
            Instructor: <b> {{ webinar?.instructor?.name }} </b>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
