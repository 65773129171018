<nav class="sb-topnav navbar navbar-expand-lg p-0 brand-top-nav-bg-color">
  <!-- Navbar Brand-->
  <a class="navbar-brand brand-bg-color brand-pd" [href]="url">
    <img class="img-fluid" src="/assets/img/allmock-white-logo.png" alt=" " />
  </a>
  <!-- Sidebar Toggle-->
  <a
    class="border-0 order-1 order-lg-0 me-4 ms-lg-2 me-lg-0 hamburger"
    id="sidebarToggle"
    (click)="sideNavToggler($event, 'toggler')"
    href="javascript:void(0)"
  >
    <img src="/assets/img/hamburger.png" alt="" />
  </a>
  <div class="pack-container border rounded-pill ms-4">
    <span class="pkg-btn mx-2 ms-3" *ngIf="auth.check && auth.isStudent">
      {{ auth.user.package == "free" ? "Free trial" : auth.user.package }}
    </span>
    <button
      (click)="upgradeMembership()"
      *ngIf="auth.check && auth.isStudent"
      class="btn brand-btn-primary rounded-pill pkg-btn pkg-upgrade-btn"
    >
      Upgrade now
    </button>
  </div>
  <!-- Navbar Search-->
  <form
    class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"
  >
    <!-- <div class="input-group">
            <input class="form-control" type="text" placeholder="Search for..." aria-label="Search for..."
                aria-describedby="btnNavbarSearch" />
            <button class="btn btn-primary" id="btnNavbarSearch" type="button"><i
                    class="fas fa-search"></i></button>
        </div> -->
  </form>
  <!-- Navbar-->
  <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 user-menu">
    <li class="nav-item">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="mt-3 fs-6 text-uppercase"
      >
        <i class="fab fa-modx"></i> {{ currentModule }}
        <i class="fas fa-chevron-down"></i>
      </button>
      <mat-menu #menu="matMenu" class="p-0">
        <button
          mat-menu-item
          *ngFor="let _module of modules"
          (click)="changeModule(_module.name)"
        >
          {{ _module.title }}
        </button>
      </mat-menu>
    </li>
    <li class="nav-item dropdown">
      <a
        class="nav-link d-flex dropdown-toggle hide-down-arrow"
        id="navbarDropdown"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div
          class="user-icon"
          [style.background]="'url(' + picture + ')'"
        ></div>
        <div class="user-info text-dark px-3">
          {{ auth.user.name }} <br />
          <small class="text-capitalize sub-title">{{
            auth.user.role?.toLowerCase()
          }}</small>
        </div>
        <div class="down-arrow pt-2">
          <img src="/assets/img/down-arrow.png" alt="" />
        </div>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="navbarDropdown"
      >
        <li class="list-group-item border-0 text-left px-0 pb-0">
          <a class="dropdown-item" routerLink="/my-account">
            <i class="fas fa-user-circle"></i> My account
          </a>
        </li>

        <li class="list-group-item border-0 text-left px-0">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            (click)="auth.logout()"
          >
            <i class="fas fa-sign-out"></i> Logout
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <nav
      class="sb-sidenav accordion sb-sidenav-dark brand-bg-gradient-color"
      id="sidenavAccordion"
    >
      <div class="sb-sidenav-menu">
        <div class="nav">
          <!-- <div class="sb-sidenav-menu-heading pt-0 fs-6" *ngIf="auth.user.role == 'STUDENT'">
                        plan: <strong>{{ auth.user.package }}</strong>
                    </div> -->
          <!-- <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 user-menu">
                        <li class="nav-item dropdown">
                            <a class="nav-link d-flex dropdown-toggle hide-down-arrow" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false"> 
                                <div class="user-icon"  [style.background]="'url('+picture+')'">
                                    
                                </div>
                                <div class="user-info text-dark px-3">
                                    {{ auth.user.name }} <br>
                                    <small class="text-capitalize sub-title">{{ auth.user.role?.toLowerCase() }}</small>
                                </div>
                                <div class="down-arrow pt-2">
                                    <img src="/assets/img/down-arrow.png" alt="">
                                </div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li class="list-group-item border-0 text-left px-0 pb-0"><a class="dropdown-item" routerLink="/my-account">
                                    <i class="fas fa-user-circle"></i> My account
                                    </a>
                                </li>
                                
                                <li class="list-group-item border-0 text-left px-0"><a class="dropdown-item" href="javascript:void(0)" (click)="auth.logout()">
                                    <i class="fas fa-sign-out"></i> Logout
                                </a></li>
                            </ul>
                        </li>
                    </ul> -->
          <a class="nav-link" routerLink="/">
            <div class="sb-nav-link-icon"><i class="far fa-home"></i></div>
            Dashboard
          </a>

          <a
            class="nav-link"
            routerLink="/admin/users"
            *ngIf="auth.isAdmin || auth.isCordinator"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-users"></i></div>
            Users
          </a>

          <a
            class="nav-link"
            routerLink="/practice-tests"
            *ngIf="auth.isStudent"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-vials"></i></div>
            Practice Tests
          </a>

          <a class="nav-link" routerLink="/test/results" *ngIf="auth.isStudent">
            <div class="sb-nav-link-icon"><i class="fas fa-poll-h"></i></div>
            Results
          </a>
          <a class="nav-link" routerLink="/test/results" *ngIf="auth.isTeacher">
            <div class="sb-nav-link-icon"><i class="fas fa-poll-h"></i></div>
            Results
          </a>

          <a class="nav-link" routerLink="/news" *ngIf="auth.isAdmin">
            <div class="sb-nav-link-icon"><i class="fas fa-poll-h"></i></div>
            News
          </a>

          <a
            class="nav-link"
            routerLink="/admin/mock-test"
            *ngIf="auth.isAdmin || auth.isCordinator"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-vials"></i></div>
            Mock test
          </a>

          <a
            class="nav-link"
            routerLink="/admin/packages"
            *ngIf="
              router.url != '/academy' && (auth.isAdmin || auth.isCordinator)
            "
          >
            <div class="sb-nav-link-icon"><i class="fas fa-tape"></i></div>
            Packages
          </a>

          <a
            class="nav-link"
            routerLink="/admin/cources"
            *ngIf="auth.isAdmin || auth.isCordinator"
          >
            <div class="sb-nav-link-icon"><i class="fab fa-osi"></i></div>
            Courses
          </a>

          <a
            class="nav-link"
            routerLink="/student/my-courses"
            *ngIf="auth.isStudent"
          >
            <div class="sb-nav-link-icon"><i class="fab fa-osi"></i></div>
            My Courses
          </a>

          <a class="nav-link" routerLink="/news" *ngIf="auth.isStudent">
            <div class="sb-nav-link-icon"><i class="fas fa-poll-h"></i></div>
            News & Updates
          </a>

          <a class="nav-link" routerLink="/admin/webinar" *ngIf="auth.isAdmin">
            <div class="sb-nav-link-icon"><i class="fab fa-osi"></i></div>
            Live Classes & Webinars
          </a>
          <a
            class="nav-link"
            routerLink="/online/dashboard"
            *ngIf="auth.isStudent"
          >
            <div class="sb-nav-link-icon"><i class="fab fa-osi"></i></div>
            Live Classes & Webinars
          </a>

          <hr class="m-0 p-0" />
          <a class="nav-link" routerLink="/settings" *ngIf="auth.isAdmin">
            <div class="sb-nav-link-icon"><i class="fa fa-cog"></i></div>
            Settings
          </a>
          <a class="nav-link" routerLink="/admin/coupons" *ngIf="auth.isAdmin">
            <div class="sb-nav-link-icon"><i class="fa fa-quote-left"></i></div>
            Coupons
          </a>

          <a
            class="nav-link"
            routerLink="/admin/pages/home"
            *ngIf="router.url != '/academy' && auth.isAdmin"
          >
            <div class="sb-nav-link-icon"><i class="fas fa-vials"></i></div>
            Pages [Meta Info]
          </a>

          <a class="nav-link" routerLink="/admin/faqs" *ngIf="auth.isAdmin">
            <div class="sb-nav-link-icon">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            Faqs
          </a>
          <a
            class="nav-link"
            routerLink="/admin/testimonials"
            *ngIf="auth.isAdmin"
          >
            <div class="sb-nav-link-icon"><i class="fa fa-quote-left"></i></div>
            Testimonials
          </a>

          <a class="nav-link" routerLink="/support">
            <div class="sb-nav-link-icon"><i class="fas fa-list"></i></div>
            Support
          </a>

          <a href="javascript:void(0)" (click)="auth.logout()" class="nav-link">
            <div class="sb-nav-link-icon"><i class="fas fa-sign-out"></i></div>
            Logout
          </a>

          <div
            class="upgrade-container p-3 position-absolute bottom-0 mb-4"
            *ngIf="auth.user.role == 'STUDENT' && auth.user.package == 'Free'"
          >
            <h2 class="text-white mb-0">Go Premium</h2>
            <p class="m-0">
              Experience outcome-oriented CELPIP full modules mock tests and an
              array of additional features to enhance your preparation.
            </p>
            <p class="text-center mt-3">
              <button
                class="btn btn-primary bg-white text-dark border-0 w-100"
                (click)="upgradeMembership()"
              >
                Upgrade Now
              </button>
            </p>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <div
        class="container-fluid pb-5"
        [ngClass]="{
          'px-0': router.url.indexOf('settings') != -1,
          'px-4': router.url.indexOf('settings') == -1
        }"
      >
        <h1 class="mt-4 border-bottom pb-3" *ngIf="data?.title">
          {{ data?.title }}
        </h1>

        <router-outlet></router-outlet>
      </div>
    </main>
    <!-- <footer class="py-4 bg-light mt-auto">
            <div class="container-fluid px-4">
                <div class="d-flex align-items-center justify-content-between small">
                    <div class="text-muted">Copyright &copy; Allmock 2023</div>
                    <div>
                        <a href="#">Privacy Policy</a>
                        &middot;
                        <a href="#">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer> -->
  </div>
</div>
