import { Component } from '@angular/core';

@Component({
  selector: 'lf-spinner',
  templateUrl: './lf-spinner.component.html',
  styleUrls: ['./lf-spinner.component.scss']
})
export class LfSpinnerComponent {

}
