import { RedirectIfAuthenticated } from './redirect-if-authenticated.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import { EmailVerifiedGuard } from './guards/email-verified.guard';
import { InvalidateTokenComponent } from './invalidate-token/invalidate-token.component';



@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SocialCallbackComponent,
    InvalidateTokenComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule
  ],
  providers: [ AuthGuard, RedirectIfAuthenticated, EmailVerifiedGuard ]
})
export class AuthModule { }
