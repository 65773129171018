import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-webinar-detail-popup',
  templateUrl: './webinar-detail-popup.component.html',
  styleUrls: ['./webinar-detail-popup.component.scss'],
})
export class WebinarDetailPopupComponent {
  webinar: any;
  constructor(
    public dialogRef: MatDialogRef<WebinarDetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.webinar = data.webinar.webinar;
    console.log(data, this.webinar);
  }
}
