import { Package } from './package';
import { map } from 'rxjs';
import { Service } from './../../shared/services/service.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PackageService extends Service {

  public urlPrefix: string = "admin";

  constructor(public httpClient: HttpClient) { 
    super(httpClient, "packages");
  }

  public getAllPackages(type: string = '') {
    return this.get(`type=${type}`).pipe(
      map((response: any) => {
        const res = {
          data: response.data.map((_package: any) => new Package(_package)) 
        }
        return res;
      })
    )
  }

  getWebinarPackage(type = 'webinar') {
    return this.httpClient.get(`${this.api}api/webinar-packages`)
  }
}
