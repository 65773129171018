<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-6 d-sm-none d-md-block">
      <div class="register-heading d-sm">
        <h2>First Step Toward your Dreams !</h2>
      </div>
      <img [src]="apiUrl+'assets/images/register.png'" class="w-100" alt="" srcset="">
    </div>
    <div class="col-md-1"></div>
    <div class="col-md-12 col-lg-4 mt-5 px-4 py-2 rounded bg-white left-pageHeading">
      <h1 class="mb-1 signup-title">Sign up</h1>
      <p>Join thousands of students globally in a trusted learning experience with Allmock!</p>
      <!-- <p *ngIf="errors" class="text-danger text-start">{{ errors }}</p> -->
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" [action]="apiUrl+'login?redirect_uri='+redirectUri" method="post" id="appLogin">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" placeholder="Enter full name" formControlName="name" required>
          <mat-error *ngIf="errors" class="error p-0 text-danger">{{ errors?.name }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Email Id</mat-label>
          <input matInput placeholder="Enter email id to login" name="email" formControlName="email" required>
          <mat-error *ngIf="errors">{{ errors?.email }}</mat-error>
        </mat-form-field>
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Country</mat-label>
              <!-- <input matInput type="text" placeholder="Enter country" formControlName="country"> -->
              <mat-select formControlName="country" (selectionChange)="countryChange($event)">
                <mat-option [value]="country.name" *ngFor="let country of countries" selected>
                  {{ country.name }} ({{ country.dial_code }})
                </mat-option>
              </mat-select>
              <mat-hint *ngIf="errors" class="error p-0 text-danger">{{ errors?.country }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Mobile number</mat-label>
              <input matInput type="text" required placeholder="Enter mobile number" formControlName="mobileNumber">
              <span matTextPrefix>
                {{ $any(loginForm.get('dialCode')).value }} &nbsp; &nbsp;
              </span>
              <mat-error *ngIf="errors" class="text-danger">Please enter the contact number</mat-error>
            </mat-form-field>
          </div>
          
        </div>

        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password" placeholder="Enter password" required name="password" formControlName="password">
              <mat-error *ngIf="errors" class="error p-0 text-danger">{{ errors?.password }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput type="password" placeholder="Enter password" required formControlName="confirmPassword">
              <mat-error *ngIf="errors" class="error p-0 text-danger">{{ errors?.confirmPassword }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col text-start p-0 my-2 mb-3" style="margin-top:-15px">
            <mat-checkbox formControlName="termCondition" color="warn">By clicking register, you agree to our <a [href]="apiUrl+'terms-and-conditions'">terms of
              services</a>  and that you
              have read our data use policy, including our cookie use</mat-checkbox>
            <p *ngIf="errors" class="error ps-2 text-danger">{{ errors?.termCondition }}</p>
          </div>
        </div>

        <button mat-raised-button [disabled]="loading" type="submit" class="w-100 mb-2" color="primary">
          Register
          <mat-spinner color="accent" *ngIf="loading" class="float-end ms-1 mt-1" style="width: 15px; height: 15px;">
          </mat-spinner>
        </button>

      </form>
      <div class="row justify-content-md-center login-bottom">
        <div class="col-md-12 mt-4 py-3 rounded bg-white login-bottom_wrapper">
          <p class="daccnt">
            Already Member ?
            <a routerLink="/login" class="col text-start">Login now</a>
          </p>
          <h4>
            Or sign in with
          </h4>
          <div class="row justify-content-md-center">
            <button mat-fab (click)="socialLogin()" matTooltip="Primary" color="transparent"
              aria-label="Example mini fab with a heart icon">
              <img src="/assets/img/Google__G__Logo.svg.webp" width="30" alt="" srcset="">
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
