<!-- footer -->
<footer class="allmock-footer-22 position-relative">
  <div class="footer-sub">
    <div class="container">
      <div class="text-txt">
        <!-- <div class="right-side text-center">
          <h4>Do you want to our best popular online courses</h4>
          <a class="btn btn-primary mt-4" href="about.html">Get Started<i class="fa fa-angle-double-right"
              aria-hidden="true"></i></a>
        </div> -->
        <div class="row">
          <div class="col-12 text-center">
            <img [src]="apiUrl+'assets/images/footer-logo.svg'" alt="">
            <p class="text mt-2">
              Your success is the only metrics we chase.
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="footer-navigation">
              <a href="/">Home</a>
              <a href="/about">About Us</a>
              <a href="/pricing">Pricing</a>
              <a href="https://blog.allmock.com" target="_blank">Blogs</a>
              <a href="/support" target="_blank">Support</a>
            </div>

            <div class="copyright-footer text-center mt-4">
              <div class="container">
                <p>
                  <a [href]="apiUrl+'privacy-policy'" class="text"> Privacy Policy </a> <span class="text"> | </span>
                  <a [href]="apiUrl+'terms-and-conditions'" class="text">Terms and Conditions</a> <span class="text">
                    | </span>
                  <a [href]="apiUrl+'refund-policy'" class="text">Refund Policy</a>
                </p>
              </div>
            </div>

            <ul class="social">
              <li><a href="https://www.facebook.com/allmockedu/" target="_blank"> <i class="fab fa-facebook-f"></i></a>
              </li>
              <li><a href="https://twitter.com/Allmockedu" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li><a href="https://www.linkedin.com/company/allmock/" target="_blank"><i
                    class="fab fa-instagram"></i></a>
              </li>
              <li><a href="https://youtube.com/@Allmock" target="_blank"><i class="fab fa-youtube"></i></a>
              <li><a href="https://www.linkedin.com/company/allmock/" target="_blank"><i
                    class="fab fa-linkedin-in"></i></a>
              </li>
            </ul>
          </div>


        </div>
      </div>
    </div>
  </div>
  <!-- copyright -->
  <div class="copyright-footer text-center">
    <div class="container">
        <p class="text">
          © All Rights Reserved Allmock 2023
        </p>
    </div>
  </div>
</footer>
<!-- //footer -->
