import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderItem } from './payment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  apiUrl: string = `${environment.api}api/`;
  constructor(private httpClient: HttpClient) { }

  addItemsToOrder(items: OrderItem[]) {
    return this.httpClient.post(`${this.apiUrl}order`, items);
  }

  findOrder(reference: string) {
    return this.httpClient.get(`${this.apiUrl}order/${reference}`);
  }
  getCoupon(): Observable<any> {
    return this.httpClient.get(`${this.apiUrl}get-coupon`);
  }

  validateCoupon(code: string): Observable<any> {
    return this.httpClient.post(`${this.apiUrl}coupon/validate`, { code });
  }

  createPayment(reference: string, token: any) {
    return this.httpClient.post(`${this.apiUrl}payment/${reference}`, token);
  }

  subscribeWebinar(reference: string, token: any) {
    return this.httpClient.post(`${this.apiUrl}payment/free/subscription/${reference}`, token);
  }

  orderResponse(reference: string) {
    return this.httpClient.post(
      `${this.apiUrl}payment/response/${reference}`,
      {}
    );
  }

  deleteItem(id: number) {
    return this.httpClient.delete(`${this.apiUrl}checkout/item/${id}`);
  }


}
