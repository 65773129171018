import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  ActivationEnd,
} from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { AuthService } from './auth/auth.service';
import { filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showHeader = false;
  testRunnerHeader = false;
  showSidebar = false;
  showFooter = false;
  apiUrl = environment.api;

  private lastPoppedUrl: any;
  private yScrollStack: any[] = [];

  constructor(
    private router: Router,
    private location: Location,
    public activatedRoute: ActivatedRoute,
    private auth: AuthService
  ) {
    activatedRoute.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length) {
        if (params.module && params.module != undefined) {
          localStorage.setItem('module', params.module);
        }

        if (params?.redirect_uri) {
          localStorage.setItem('intendedUrl', params?.redirect_uri);
          // check if module exists
          let module = 'celpip';
          if (params?.redirect_uri.includes('ielts')) {
            module = 'ielts';
          }
          localStorage.setItem('module', module);
        }
      }
    });
  }

  ngOnInit() {
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof ActivationEnd) {
        let routeData: any = ev.snapshot?.firstChild?.data;
        this.showHeader = routeData?.showHeader !== false;
        this.showSidebar = routeData?.showSidebar !== false;
        this.showFooter = routeData?.showFooter !== false;
        this.testRunnerHeader = routeData?.testRunnerHeader !== false;
      }

      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);

        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
          document.body.classList.toggle('sb-sidenav-toggled');
        }
      }
    });

    if (this.auth.check) {
      this.auth.settings();
      // open module changer
      this.auth.loadModuleChanger();
    }
  }
}
