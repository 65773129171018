<div class="row">
  <div
    class=""
    [class]="
      grid == 2
        ? 'col-md-6 col-lg-6 col-sm-6 col-xs-12'
        : 'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3'
    "
    *ngFor="let package of packages"
  >
    <div class="card p-1">
      <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-4 col pe-0">
          <img
            [src]="package.image"
            class="img-fluid p-2 pe-0"
            style="box-shadow: 2px"
            alt=""
          />
        </div>
        <div class="col-md-8 col-sm-8 col-xs-8 col">
          <h4 class="mb-1">{{ textLimit(package.title, 45) }}</h4>
          <p class="mb-0">
            <i class="fas fa-user"></i>
            <span *ngFor="let package of package.webinars; let last = last">
              {{ package.webinar?.instructor?.name }} {{ !last ? "," : "" }}
            </span>
          </p>
          <p class="m-0" (click)="openWebinarDetailPopup(package)">
            {{ textLimit(package.description, 70) }}
          </p>

          <p>
            <ng-container *ngFor="let price of package.currencyByPrice">
              <span
                class="float-start mt-1"
                *ngIf="price.currency == package.currency"
              >
                <span class="text-uppercase fw-bold">
                  <ng-container *ngIf="price.sellingPrice">
                    {{ package.currency }} {{ price.sellingPrice }}
                  </ng-container>
                  <ng-container *ngIf="!price.sellingPrice">
                    Free
                  </ng-container>
                </span>
              </span>
            </ng-container>

            <a
              (click)="bookTest(package)"
              href="javascript:void(0)"
              class="reg-now"
              >Subscribe Webinar</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
