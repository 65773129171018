import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RedirectIfAuthenticated implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _isValid = this.authService.isLoggedIn();
    console.log(next.queryParams['invalidate'])
    if(next.queryParams['invalidate'] == 'true') {
      console.log('Invalidate');
      this.authService.removeAuthToken();
    }
    if (_isValid) {
      this.router.navigate(['/dashboard']);
    }
    return true;
  }
}
