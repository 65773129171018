import { ConfirmComponent } from './../component/confirm/confirm.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoaderDialogComponent } from '../component/loader-dialog/loader-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class Notify {
  private duration: number = 5 * 1000; // display notification for 5 seconds

  constructor(private _snackBar: MatSnackBar, public dialog: MatDialog) {}

  success(message: string = 'Success') {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'snack-bar-success',
      duration: this.duration,
    });
  }

  error(message = 'Error') {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: 'snack-bar-danger',
      duration: this.duration,
    });
  }

  confirm() {
    return this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Delete',
        description:
          'Would you like to delete this item, it will not be recoverable?',
      },
    });
  }

  loading() {
    return this.dialog.open(LoaderDialogComponent, {
      data: {},
    });
  }
}
