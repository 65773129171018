export const Role = {
    ADMIN: "ADMIN",
    CORDINATOR: "CORDINATOR",
    TEACHER: "TEACHER",
    STUDENT: "STUDENT",
    INSTRUCTOR: "INSTRUCTOR"
  };

interface RolePermissionInterface {
    role: string;
    permissions: any[];
}

export class RolePermission implements RolePermissionInterface {
    role: string;
    permissions: any[]

    constructor(role_p: RolePermissionInterface = {
        role: '',
        permissions: [] 
    }) {
        this.role = role_p.role;
        this.permissions = role_p.permissions
    }
}