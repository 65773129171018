import { environment } from './../../../environments/environment';
import { Notify } from './../../shared/services/notification.service';
import { AuthService } from './../../auth/auth.service';
import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  loading: boolean = false;
  apiUrl: string = environment.api;
  homeUrl: string = environment.api;
  topMenus: any[] = [];
  module: any;

  constructor(
    public auth: AuthService,
    private notify: Notify,
    private router: Router
  ) {
    if (!auth.check) {
      auth.getMenus();
    }

    auth.menus.subscribe((res: any) => {
      let mod = localStorage.getItem('module');
      this.module = mod && mod != undefined ? mod : '';
      if (this.module != 'celpip') {
        this.homeUrl = this.apiUrl + this.module;
      }
      this.topMenus = res.topMenus;
    });
    console.log(this.topMenus);
    document.body.classList.remove('test-runner');
    router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        let _class = document.getElementById('navbar-toggler')?.className;
        if (_class?.indexOf('collapsed') === -1) {
          document.getElementById('navbar-toggler')?.click();
        }
      }
    });
  }

  logout(): void {
    this.auth.logout();
  }

  getUrl(url: string) {
    return url.indexOf('module') !== -1 ? url + this.module : url;
  }

  resendVerificationEmail() {
    this.loading = true;
    this.auth.resendEmail().subscribe(
      (res: any) => {
        this.loading = false;
        console.log(res);
        this.notify.success(res.message);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.notify.error(err.statusText);
      }
    );
  }
}
