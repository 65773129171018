import { environment } from './../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  apiUrl: string = environment.api;
  public loginForm: FormGroup;
  public errors: any;
  public error: string = '';
  public message = '';
  public loading: boolean = false;
  loginSubscription: Subscription = new Subscription();
  activatedRouteSubscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder, public authService: AuthService, public router: Router, private activatedRoute: ActivatedRoute) {
   
    
    this.loginForm = fb.group({
      email: new FormControl(),
      password: new FormControl(),
      confirm_password: new FormControl(),
      token: new FormControl()
    });
  }
  
  ngOnInit() {
    // Note: Below 'queryParams' can be replaced with 'params' depending on your requirements
    this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(params => {
        const email = params['email'];
        this.loginForm.patchValue({
          email: email,
          token: this.activatedRoute.snapshot.paramMap.get('token')
        })
      });
  }


  onSubmit(value: any): void {
    this.error = '';
    this.errors = [];
    this.message = '';
    this.loading = true;
    this.loginSubscription = this.authService
      .reset(value)
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.message = res.message;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 1000);
        },
        (err: HttpErrorResponse) => {
          console.log(err)
          this.loading = false;
          if(err.status == 422) {
            this.errors = JSON.parse(err.error?.message);
            console.log(this.errors);
            for (const key in this.errors) {
              if (Object.prototype.hasOwnProperty.call(this.errors, key)) {
                this.loginForm.controls[key].setErrors({'incorrect': true});
              }
            }
          } else {
          this.error = err.error?.message;
          }
        }
      );
  }

  socialLogin() {
    this.authService.socialLogin();
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
    this.activatedRouteSubscription.unsubscribe();
  }
}
