import { DashboardLayoutComponent } from './dashboard/dashboard-layout/dashboard-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { EmailVerifiedGuard } from './auth/guards/email-verified.guard';
import { AccountVerificationComponent } from './shared/component/account-verification/account-verification.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    data: {
      showHeader: false,
      testRunnerHeader: false,
      dashboard: true,
      showFooter: false,
    },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'verification/:token',
    canActivate: [AuthGuard, EmailVerifiedGuard],
    component: AccountVerificationComponent,
  },

  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'settings',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        title: 'Settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'admin',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'student',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'academy',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        loadChildren: () =>
          import('./academy/academy.module').then((m) => m.AcademyModule),
      },
    ],
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'online',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        loadChildren: () =>
          import('./online-service/online-service.module').then(
            (m) => m.OnlineServiceModule
          ),
      },
    ],
  },

  {
    path: '',
    children: [
      {
        path: 'ielts',
        data: {
          showHeader: false,
          testRunnerHeader: true,
          dashboard: true,
          showFooter: false,
        },
        loadChildren: () =>
          import('./ielts/ielts.module').then((m) => m.IeltsModule),
      },
    ],
  },
  {
    path: '',
    data: {
      showHeader: false,
      testRunnerHeader: true,
      showFooter: false,
    },
    loadChildren: () =>
      import('./test-runner/test-runner.module').then(
        (m) => m.TestRunnerModule
      ),
  },
  {
    path: '',
    data: {
      showHeader: false,
      testRunnerHeader: true,
      showFooter: true,
    },
    loadChildren: () =>
      import('./course-runner/course-runner.module').then(
        (m) => m.CourseRunnerModule
      ),
  },

  {
    path: '',
    data: {
      showHeader: true,
    },
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  // {path: '**', component: ErrorComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
