<!-- Toolbar -->
<nav class="navbar navbar-expand-lg bg-white navbar-dark bg-gradient shadow-sm">
  <div class="container-fluid">
    <a class="navbar-brand" href="">
      <img [src]="apiUrl + 'assets/images/logo.svg'" alt="Logo allmock" />
    </a>
    <button
      class="navbar-toggler collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarColor02"
      aria-controls="navbarColor02"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navbarColor02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 hide-for-mobile">
        <li class="nav-item"></li>
      </ul>
      <div class="d-flex">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link text-black" href="/">Go to Dashboard</a>
          </li>

          <li class="nav-item" *ngIf="!auth.check">
            <a class="nav-link" routerLink="/login">Login</a>
          </li>
          <li class="nav-item" *ngIf="!auth.check">
            <a class="nav-link" routerLink="/signup">Sign up</a>
          </li>
          <li class="nav-item dropdown" *ngIf="auth.check">
            <a
              class="nav-link dropdown-toggle text-black"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fas fa-cog"></i> {{ auth.user.name }}
            </a>
            <ul class="dropdown-menu p-0 rounded-0 border-0">
              <!-- <li>
                  <a class="dropdown-item py-2" routerLink="/my-account">My account</a>
                </li> -->
              <li>
                <a
                  class="dropdown-item py-2"
                  href="javascript:void(0)"
                  (click)="logout()"
                  >Logout</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
